<template>
  <div class="wrapper">
    <div class="header">
      <div class="handle left-handle">
        <span>打开</span>
        <a-divider type="vertical" />
        <span>保存</span>
        <a-divider type="vertical" />
        <span @click="switchTheme()">主题</span>
      </div>
      <div class="handle right-handle">
        <span>打开</span>
        <a-divider type="vertical" />
        <span>保存</span>
      </div>
    </div>
    <div id="editor" ref="editor"></div>
  </div>
</template>
<script>
import * as monaco from 'monaco-editor';
export default {
  name: 'Monaco',
  data() {
    return {
      monacoDiff: null,
      oldContent: "111",
      newContent: '222',
      settings: {
        theme: 'vs'
      }
    };
  },
  mounted() {
    // this.monacoDiff = monaco.editor.create(document.getElementById('editor'), {
    //     value: 'const foo = () => 0;',
    //     language: 'javascript',
    // theme: 'vs-dark'
    // });

    this.init();
  },
  created() {
  },
  methods: {
    init() {
      if (this.$refs.editor) {
        // 原始的模型
        const originalModel = monaco.editor.createModel(
          this.oldContent
        );
        // diff模型
        const modifiedModel = monaco.editor.createModel(
          this.newContent
        );

        this.monacoDiff = monaco.editor.createDiffEditor(
          this.$refs.editor,
          {
            // 原始可编辑
            originalEditable: true,
            // 只读
            readOnly: false,
            automaticLayout: true,
            scrollBeyondLastLine: false,
            theme: this.settings.theme
          }
        );
        this.monacoDiff.setModel({
          modified: modifiedModel,
          original: originalModel
        });
      }
    },
    switchTheme() {
      this.monacoDiff.dispose();
      this.settings.theme = this.settings.theme === "vs-dark" ? "vs" : "vs-dark";
      this.init();
    }
  },
  beforeDestroy() {
    this.monacoDiff.dispose();
  }
};
</script>
<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}

ul,
ol,
li {
  list-style: none;
}

.wrapper {
  margin: 5px;
}

.wrapper .header {
  height: 30px;
  line-height: 30px;
  border: 1px solid;
  border-bottom: none;
  display: flex;
  padding: 0 10px;
  color: #000;
  cursor: pointer;

  .handle {
    flex: 1;
  }
}

.wrapper #editor {
  height: calc(100vh - 40px);
}

#editor {
  width: 100%;
  height: 50vh;
  border: 1px solid #000;
}
</style>

